import React, { useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import FooterCorp from '../modulos/footerCorp';
import obtieneDatos from '../tools/obtieneDatosCookiesUsuario';
import socket from '../tools/socke'; 
import FileUpload from './FileUpload';
import DevuelveResoluciones from './DevuelveResoluciones';
import './ChatComponent.css'; 
import axios from 'axios';
import ModalInasistencia from './Modal';
import { useForm } from 'react-hook-form';
import devuelveEscuela from '../tools/devuelveEscuela'



const Menu = () => {
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const [datos,setDatos]=useState()
  const [muestraCargaFile,setMuestroCargaFile]=useState(false)
  const [resolucionSeleccionada,setResolucionSeleccionada]=useState([])
  const [tipoSeleccionado,setTipoSeleccionadoi]=useState("docx")
  const [cangando,setCargando]=useState(false)
  const [nuevaResolucion, setNuevaResolucion]=useState(false)
  const [mensajex,setMensajex]=useState("nada")
    const Usuario=obtieneDatos();
    const navitate = useNavigate()

    if(!Usuario.id){
      alert("Acceso no Autorizado")
       window.location.href='./';
    }else{
      
    }
   
 
    const location = useLocation();
const obtengoResoluciones=async()=>{

  const client = axios.create({
    baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/resoluciones/obtieneResoluciones/"
    });
  try {
  
    const listaFull=await client.post("/"+"?escuela="+devuelveEscuela())
    if(listaFull){
      console.log(listaFull)
      setDatos(listaFull.data)
    }
}catch{

}
}
const actualizaResoluciones=async(objeto)=>{
  const client = axios.create({
    baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/resoluciones/actualizaResolucion/"
    });
  try {
    const listaFull=await client.post("/"+objeto.id,objeto)
    if(listaFull){
      const mensajeConId = {
        id: Usuario.id,
        texto: "pdf",
    };
    socket.emit('mensaje', mensajeConId);
      console.log(listaFull)
      obtengoResoluciones()
      setCargando(false)
    }
}catch{

}
}
useEffect(() => {
        
if(location.nroExpediente){
  alert(location.nroExpediente)
}
        if(!Usuario.id){
            alert("Acceso no Autorizado")
             window.location.href='./';
          }else{
            obtengoResoluciones()
          }
    }, []);
 
    
    useEffect(() => {
      socket.emit('identificarUsuario',Usuario.apellido)
      socket.on('mensaje', (msg) => {
          if (msg.texto === "pdf") {
             
                obtengoResoluciones() }
              
          
         
      });
      socket.on('cs', (msg) => {
        
           
              alert("Cerrando Sesion de Emergencia!");
            navitate('/')}
            
        
       
    );
      return () => {
          socket.off('mensaje'); 
      };
  }, []);

const veoNuevaResolucion=()=>{
  setNuevaResolucion(true)

}
const creoResolucion=async(data)=>{


var dato=data.nroResolucion
    const valor = dato.replace(/\//g, '-');
    var nombre=""
    if(devuelveEscuela()=="1"||Usuario.destino=="7"){
      nombre="DG - "+valor
    }
    else if(devuelveEscuela()=="25"){
      nombre="EP - "+valor
    }
    else if(devuelveEscuela()=="26"){
      nombre="ES - "+valor
    }
    else if(devuelveEscuela()=="27"){
      nombre="EE - "+valor
    }
    else if(devuelveEscuela()=="28"){
      nombre="EC - "+valor
    }


const nuevaResolucion={
  nroResolucion:nombre,
  escuelaId:Usuario.destino,
  detalle:data.detalle,
  expedienteID:data.expedienteID,
  condicion:data.condicion,
  estado:"PENDIENTE",
 
}


    const client = axios.create({
      baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/resoluciones/creaResolucion/"
      });
    try {
    
      const listaFull=await client.post("/",nuevaResolucion)
      if(listaFull){
      
        if(listaFull.data.message=="Validation error"){
          alert("Ya hay una resolucion con ese nombre") 
         
        obtengoResoluciones()
        setNuevaResolucion(false)
       reset()}
        else{
          const mensajeConId = {
            id: Usuario.id,
            texto: "pdf",
        };
        socket.emit('mensaje', mensajeConId);
        obtengoResoluciones()
        setNuevaResolucion(false)
       reset()
      }}
  }catch{
  
  }
  
}
const actualiza=(valor,tipo)=>
{


  setTipoSeleccionadoi(tipo)
  setResolucionSeleccionada(valor)
  setMuestroCargaFile(true)
 
}
const realiza=(valor,tipo)=>
  {
const fecha=new Date()
var resolucion={}
    if(tipo=="pdf"){
      resolucion={
        "id":resolucionSeleccionada.id,
        "archivoPdf":valor,
        "fechaArchivoPdf":fecha,
        "usuarioIdPdf":Usuario.id,
        "destinoIdPdf":Usuario.destino,
        "estado":"COMPLETO"
      }
    }else{
       resolucion={
        "id":resolucionSeleccionada.id,
        "archivoWord":valor,
        "fechaArchivoWord":fecha,
        "usuarioIdWord":Usuario.id,
        "destinoIdWord":Usuario.destino,
         "estado":"EN_PROGRESO"
      }
    }
   
    actualizaResoluciones(resolucion)
   
    setMuestroCargaFile(false)
   
  }
const borrarWord=async(valor)=>{
  setCargando(true)
  const confirmDelete = window.confirm(`¿Estás seguro de que deseas eliminar el archivo: ${valor.archivoWord}?`);
if(confirmDelete){
  try {
    const fileName = valor.archivoWord;
    const encodedFileName = encodeURIComponent(fileName);
    const response = await axios.delete(`https://wwwa.isepsantafe.edu.ar/expedientes/resoluciones/borraArchivo/${encodedFileName}`);
    
    if(response){
      const resolucion={
        "id":valor.id,
        "archivoWord":null,
        "fechaArchivoWord":null,
        "usuarioIdWord":null,
        "destinoIdWord":null,
         "estado":"PENDIENTE"
      }
        actualizaResoluciones(resolucion)

      obtengoResoluciones()
    }
}catch{

}
}else{ setCargando(false)}
 
}
const borrarPdf=async(valor)=>{
  setCargando(true)
  setCargando(true)
  const confirmDelete = window.confirm(`¿Estás seguro de que deseas eliminar el archivo: ${valor.archivoPdf}?`);
if(confirmDelete){
 

  try {
    const fileName = valor.archivoPdf;
    const encodedFileName = encodeURIComponent(fileName);
    const response = await axios.delete(`https://wwwa.isepsantafe.edu.ar/expedientes/resoluciones/borraArchivo/${encodedFileName}`);
    
    if(response){
      const resolucion={
        "id":valor.id,
        "archivoPdf":null,
        "fechaArchivoPdf":null,
        "usuarioIdPdf":null,
        "destinoIdPdf":null,
        "estado":"EN_PROGRESO"
        }
        actualizaResoluciones(resolucion)

      obtengoResoluciones()
    }
}catch{

}



}else{setCargando(false)}
 
}

const mensaje = () => {
  if (socket) {
    socket.emit('identificarUsuario',Usuario.apellido); // Reemplaza `userId` con el ID real del usuario
  
  }
  
};
const desconecto = () => {
  if (socket) {
    socket.emit('cs'); // Reemplaza `userId` con el ID real del usuario
  //  socket.emit('disconnectAll'); // Reemplaza `userId` con el ID real del usuario
  
  }
  
};
const devuelveTitulo=()=>{
  const valor=devuelveEscuela()
  if(valor==1){
    return "DIRECCIÓN GENERAL"
  }
  else if(valor==25){
    return "DIRECCIÓN ESCUELA DE POLICÍA"
  }
  else if(valor==26){
    return "DIRECCIÓN ESCUELA SUPERIOR"
  }
  else if(valor==27){
    return "DIRECCIÓN ESCUELA DE ESPECIALIDADES"
  }
  else if(valor==28){
    return "DIRECCIÓN ESCUELA DE INVESTIGACIONES"
  }
  else if(valor==7){
    return "DE TODAS LAS ESCUELAS"
  }
}

 
const [buscador, setBuscador] = useState('');

const handleChange = (event) => {
  setBuscador(event.target.value);
};

const manejoBuscador = async(event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`https://wwwa.isepsantafe.edu.ar/expedientes/resoluciones/buscaResolucion?palabra=${buscador}`);
      console.log(response)
    } catch (error) {
      
    }
   
};
const borraResolucion=async(valor)=>{
  const confirmDelete = window.confirm(`¿Estás seguro de que deseas eliminar la resolucion? Recuerde que puede porque no tiene archivos asociados!!!`);
  if(confirmDelete){
  
  try {
    const response = await axios.delete(`https://wwwa.isepsantafe.edu.ar/expedientes/resoluciones/borraResolucion/`+valor);
   if (response){
    
      const mensajeConId = {
        id: Usuario.id,
        texto: "pdf",
    };
    socket.emit('mensaje', mensajeConId);
     
      obtengoResoluciones()
      setCargando(false)
    }
    
   
  } catch (error) {
    
  }}
}
const cambioCondicion=async(valor)=>{
setCargando(true)
  const client = axios.create({
    baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/resoluciones/actualizaResolucion/"
    });
  try {
    const listaFull=await client.post("/"+valor.id,{"condicion":valor.condicion})
    if (listaFull){
    
      const mensajeConId = {
        id: Usuario.id,
        texto: "pdf",
    };
    socket.emit('mensaje', mensajeConId);
     
      obtengoResoluciones()
      setCargando(false)
    }
  } catch (error) {
    
  }
}
  return (


    <div style={{textAlign:"center", marginTop:"15px"}}><h2>Resoluciones {devuelveTitulo()}</h2>
 
{Usuario.destino=="7"?<><button onClick={()=> mensaje()}>mensaje</button>

<button onClick={()=> desconecto()}>desconecto a todes</button></>:""}

    <button className="btn btn-warning" onClick={ ()=> navitate('/usuarioLogueado')}>Volver</button>
    <ModalInasistencia isOpen={cangando} onClose={() => setCargando(false)}>
        <div>
        <h3>Actualizando</h3>
        </div>
        </ModalInasistencia>
        <ModalInasistencia isOpen={nuevaResolucion} onClose={() => setNuevaResolucion(false)}>
        <div>
          <hr />
        <h3>Nueva Resolución</h3>
        <form className="form_use" onSubmit={handleSubmit(creoResolucion)}>
          
              <div className="form__container">
                <div className="form__group">
                  {errors.nroResolucion?.type === 'required' && (
                    <span className="aviso-campos-loguin">*</span>
                  )}
                  <input
                    type="text"
                    className="form__input"
                    id="nroResolucion"
                    {...register('nroResolucion', { required: true })}
                     
                  />
                  <label className="form__label">Nro de Resolución</label>
                  <span className="form__line"></span>
                </div>
                <div className="form__group">
                 
                  <textarea
                    type="textarea"
                    className="form__input"
                    id="detalle"
                    {...register('detalle')}
                     
                  />
                  <label className="form__label">Detalle</label>
                  <span className="form__line"></span>
                </div>
                <div className="form__group">
             
                  <input
                    type="number"
                    className="form__input"
                    id="detalle"
                    {...register('expedienteID')}
                     
                  />
                  <label className="form__label">Nro de Expediente</label>
                  <span className="form__line"></span>
                </div>
                <div  className="form__input">
                
                  <select
                 
                   
                    id="condicion"
                    {...register('condicion', { required: true })}
                     
                  ><option value="Interno">Interno</option>
                  <option value="Público">Público</option>
                  </select>
                 
                </div>
                
               

                <input type="submit" className="form__submit" value="Continuar" />
              </div>
            </form>
        </div>
        </ModalInasistencia>
    <div className="button-container"><button className="chat-button" onClick={veoNuevaResolucion}>Subir nueva</button>  </div>
    {datos?<DevuelveResoluciones cosas={[datos]} editaWord={(value)=>actualiza(value,"docx")}  editaPdf={(value)=>actualiza(value,"pdf")}borrarWord={(value)=>borrarWord(value)} cambioCondicion={(value=>cambioCondicion(value))}borrarPdf={(value)=>borrarPdf(value)} borraResolucion={(value)=>borraResolucion(value)}/>:""}
    {muestraCargaFile?<>

    <ModalInasistencia isOpen={muestraCargaFile} onClose={() => setMuestroCargaFile(false)}>
          <div>           
            
          <FileUpload resolucion={resolucionSeleccionada.nroResolucion} tipo={tipoSeleccionado} myID={(nombreArchivo)=>realiza(nombreArchivo,tipoSeleccionado)}/>
           
          </div>
        </ModalInasistencia>
    </>:""}

    <div class="footer"><FooterCorp /></div>  
    </div>
  );
};

export default Menu;


