import React, { useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import FooterCorp from '../modulos/footerCorp';
import obtieneDatos from '../tools/obtieneDatosCookiesUsuario';
import FileUpload from './FileUpload';
import './ChatComponent.css'; 

const Documentos = () => {
  const [mostrar,setMostrar]=useState()
  const Usuario=obtieneDatos();
  const navitate = useNavigate()
  if(!Usuario.id){
      alert("Acceso no Autorizado")
       window.location.href='./';
  }
  const location = useLocation();


useEffect(() => {
        
if(location.nroExpediente){
 
}
if (
  Usuario.identificacion === "522708" ||
  Usuario.identificacion === "673358" ||
  Usuario.identificacion === "573523" 
  
) {
  setMostrar(true)
} else {
  alert("Acceso no Autorizado");
  window.location.href = './usuarioLogueado';
}
    }, []);

return (<>

  {mostrar?
  <><div style={{ textAlign: "center", marginTop: "20px" }}>
   
   <h1>Documentos Dirección General</h1>
   <hr />
   <button
     className="btn btn-warning"
     style={{ marginBottom: "20px" }} 
     onClick={() => navitate('/usuarioLogueado')}
   >
     Volver
   </button>

 
   <div
     style={{
       border: "1px solid black",
       minHeight: "150px", 
       padding: "20px", 
       marginBottom: "20px", 
       borderRadius: "8px" 
     }}
   >
     <FileUpload />
   </div>
   <div className="footer" style={{ marginTop: "40px" }}>
     <FooterCorp />
   </div>
 </div>
 </>:""}
  </>
);
};
export default Documentos;