import React, { useState, useEffect } from 'react';
import './Menu.css'; // Asegúrate de incluir este archivo CSS
import {useNavigate,useParams, useSubmit} from 'react-router-dom';
import FooterCorp from '../modulos/footerCorp';
import obtieneDatos from '../tools/obtieneDatosCookiesUsuario';
import CsvDropzone from './CsvDropzone';
import GestorUsuariosCalificaciones from './GestorUsuariosCalificaciones'
import GestorInstancias from './GestorInstancias'
import './CsvDropzone.css'; // Asegúrate de importar el archivo CSS
import Docentes from '../Docentes/Docentes'
import FileUpload from './FileUpload';

const Menu = () => {

const [muestroCsv,setMuestroCsv]=useState(true)
    const Usuario=obtieneDatos();
    


    useEffect(() => {
        

        if(!Usuario.id){
            alert("Acceso no Autorizado")
             window.location.href='./';
          }
    }, []);
  const navitate = useNavigate()
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
const tomoDatos=(datos)=>{
    
console.log(datos)



}

const Component4 = () => <div><><FileUpload /></></div>;
  const Component1 = () => <div><><GestorInstancias /></></div>;
  const Component2 = () => <div>{
    
    muestroCsv?<CsvDropzone datos={(value)=>tomoDatos(value)}/>:""}</div>;
  const Component3 = () => <div><><GestorUsuariosCalificaciones /></></div>;
 

 
  const handleMenuClick = (component) => {
    setSelectedComponent(component);
    setIsMenuOpen(false); 
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="menu-container">
      <nav className="menu">
        <div className="hamburger" onClick={toggleMenu}>
          ☰
        </div>
        <div className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
          <div className="menu-item" onClick={() => handleMenuClick('Component1')}>Instancias</div>
          <div className="menu-item" onClick={() => handleMenuClick('Component2')}>Matricular Alumnos</div>
          <div className="menu-item" onClick={() => handleMenuClick('Component3')}>Gestion Usuarios</div>
          <div className="menu-item" onClick={() => handleMenuClick('Component4')}>Prueba</div>
          <div className="menu-item" onClick={() =>  navitate('/usuarioLogueado')}>Volver</div>
        </div>
      </nav>

      <div className="component-display">
        {selectedComponent === 'Component1' && <Component1 />}
        {selectedComponent === 'Component2' && <Component2 />}
        {selectedComponent === 'Component3' && <Component3 />}
        {selectedComponent === 'Component4' && <Component4 />}
        {selectedComponent === 'Component5' && (() =>  navitate('/usuarioLogueado'))}
       
      </div>
    <div class="footer"><FooterCorp /></div>  
    </div>
  );
};

export default Menu;


