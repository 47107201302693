import obtieneDatos from '../tools/obtieneDatosCookiesUsuario';

const devuelveEscuela=()=>{
    const Usuario=obtieneDatos()
    if(Usuario.destino==1||Usuario.destino==23||Usuario.destino==6){
      return 1
    }
    else if(Usuario.destino==2||Usuario.destino==25){
      return 25
    }
    else if(Usuario.destino==3||Usuario.destino==26){
      return 26
    }
    else if(Usuario.destino==4||Usuario.destino==27){
      return 27
    }
    else if(Usuario.destino==5||Usuario.destino==28){
      return 28
    }
    else if(Usuario.destino==7){
      return 7
    }
  }
  export default devuelveEscuela