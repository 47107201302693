import Loguin from './tools/Loguin';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FormNuevoExpte from '../src/Expedientes/formNuevoExpte';
import Edit from './tools/Edit';
import GeneraCaratula from '../src/Expedientes/generaCaratula';
import ConsultaExpediente from '../src/Expedientes/ConsultaExpediente';
import QRscanner from './modulos/QRscanner'
import ModuloUsuarios from './modulos/moduloUsuarios';
import CambiaContrasena from './modulos/CambiaContrasena';
import DevuelveLegajoInscripto from '../src/modulos/DevuelveLegajoInscripto';
import Cadetes from '../src/cadetes/Cadetes'
import Procesos from '../src/Ingresos/Procesos'
import Certificados from './certificados/Certificados';
import Constancia from './certificados/Constancia'
import GeneraRecibo from '../src/Expedientes/generaRecibo'
import EnvioMasivo from './tools/EnvioMasivo';
import Docentes from './Docentes/Docentes';
import Calificaciones from './Calificaciones/Calificaciones';
import Resoluciones from './Resoluciones/Resoluciones'
import Documentos from './documentos/Documentos';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Loguin />}/>
          <Route path='/usuarioLogueado' element={<Edit />}/>
          <Route path='/imprime' element={<GeneraCaratula />}/>
          <Route path='/Docentes' element={<Docentes />}/>
          <Route path='/imprimeRecibo' element={<GeneraRecibo />}/>
          <Route path='/nuevoExpediente' element={<FormNuevoExpte />}/>
          <Route path='/consultaExpediente' element={<ConsultaExpediente />}/>
          <Route path='/LectorQr' element={<QRscanner />}/>
          <Route path='/usuarios' element={<ModuloUsuarios />}/>
          <Route path='/cambioContrasena' element={<CambiaContrasena />}/>
          <Route path='/Postulante' element={<DevuelveLegajoInscripto />}/>
          <Route path='/Cadetes' element={<Cadetes />}/>
          <Route path='/Procesos' element={<Procesos />}/>
          <Route path='/Certificados' element={<Certificados />}/>
          <Route path='/imprimeCertificado' element={<Constancia />}/>
          <Route path='/envioMasivo' element={<EnvioMasivo/>}/>
          <Route path='/Calificaciones' element={<Calificaciones/>}/>
          <Route path='/Resoluciones' element={<Resoluciones/>}/>
          <Route path='/Documentos' element={<Documentos/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
