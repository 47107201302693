import Cookies from 'universal-cookie';
import { Table } from "reactstrap";
function ModuloDevuelveCursos (props) {
  const cookies=new Cookies();

        const usuarioPrivilegios = (
    
          <>
          <h5 onClick={()=>alert("bajo Todo")}>Certificaciones Encontradas</h5>
          <div style={{ height: "120px", overflowY: "auto" }}>
            <Table className="table-primary" bordered size="sm">
              <thead>
                <tr style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 0 }}>
                  <th>Curso</th>
                  <th>Fecha</th>
                  <th>Organizador</th>
                  <th>Condición</th>
                </tr>
              </thead>
              <tbody>
                {props.cosas && props.cosas.length > 0 ? (
                  props.cosas.map((cursos) => (
                    <tr onClick={() => props.myID(cursos)} key={cursos.id}>
                      <td>{cursos.tituloCertificado}</td>
                      <td>{cursos.fechaRealizado}</td>
                      <td>{cursos.organizadoPor}</td>
                      <td>{cursos.estado}</td>
                     
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No se encontraron certificaciones</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
        
    
    
      );  
      return (
        <div>
          
         {usuarioPrivilegios}
       
        </div>
      );
    };
export default ModuloDevuelveCursos;
