import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from "reactstrap";
import socket from '../tools/socke'; 
import regresaFecha from '../tools/regresaBienFechayDia'
const FileList = (props) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [borrando,setBorrando]=useState(false)
  var contador=1
  
const mensaje = () => {
  if (socket) {
    const mensajeConId = {
      id: "",
      texto: "Nuevo Documento",
  };
    socket.emit('mensaje',mensajeConId); 
  
  }
  
};
  const fetchFiles = async () => {
    try {
      setBorrando(true);
      const response = await axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/archivos');
      setFiles(response.data);
  
    } catch (err) {
      setError('Error al cargar los archivos');
    } finally {
      setBorrando(false);
      setLoading(false)
    }
  };
  useEffect(() => {
    

    fetchFiles();
  }, [props.enviar]);
  useEffect(() => {
    
    socket.on('mensaje', (msg) => {
        if (msg.texto === "Nuevo Documento") {
          fetchFiles();
            }
            
        
       
    });
    
    return () => {
        socket.off('mensaje'); 
    };
}, []);
  if (loading) {
    return <p>Cargando archivos...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }
const funcionBorra=async(filename)=>{
  const confirmDelete = window.confirm(`¿Estás seguro de que deseas eliminar el archivo: ${filename}?`);
  if(confirmDelete){
   
  setBorrando(true);
  const encodedFileName = encodeURIComponent(filename);

  const response = await axios.delete(`https://wwwa.isepsantafe.edu.ar/expedientes/borraArchivoPrivado/${encodedFileName}`);
 
if(response)
{   alert("Borrado Correcto") 
  mensaje()
  fetchFiles()
  setBorrando(false);
}


  }else{}
}
  return (
    <div>
     
      {files.length>0?<> <h2>Lista de Archivos</h2><Table className="table-primary" bordered size="sm">
        <thead>
          <tr style={{ cursor: 'default' }}>
            <th style={{width:"25px"}}>#</th>
            <th style={{width:"135px"}}>Archivo</th>
            <th style={{width:"85px"}}>Fecha</th>
            <th style={{width:"50px"}}>Borrar</th>
           
            
          </tr>
        </thead>
        <tbody  className="table-body">
      
          {files.map((file) => 
              <tr style={{ cursor: 'default' }} key={file.id} >
                <th>{contador++}</th>
         <th  scope="row" style={{width:"25px", cursor: 'default'}} ><a href={`https://wwwa.isepsantafe.edu.ar/uploads/${file.name}`} target="_blank" rel="noopener noreferrer">
              {file.name} 
            </a></th>
            <th>{"subido el " +regresaFecha(file.createdAt)}</th>
            <th>{borrando?<button disabled onClick={()=>funcionBorra(file.name)} class="btn btn-danger">X</button >: <button  onClick={()=>funcionBorra(file.name)} class="btn btn-danger">X</button >}</th>
            {//(file.size / (1024 * 1024)).toFixed(2)
            }
     
          </tr>
        )}

             
          

        </tbody>
      </Table></>:"No hay archivos por el momento"}
      
        
   

    </div>
  );
};

export default FileList;
