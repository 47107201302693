
import regresaBienFecha from '../tools/regresaBienFecha';
import { Table } from "reactstrap";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import ModalInasistencia from './Modal';
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'
function ModuloDevuelveInasistenciasDocentes (props) {
  const Usuario=obtieneDatos()
  const [DatosDocente, setDatosDocente] = useState(props.Docentes);
  const [modalAbierto, setModalDevuelve] = useState(false);
  const [inasistenciaSeleccionada, setInasistenciaSeleccionada] = useState(null);
  const [parametro, setParametro] = useState();
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    defaultValues: parametro // Valores por defecto del formulario
  });

  const creaDevolucion = async (valor) => {

   
  
    setInasistenciaSeleccionada(valor); // Establece la inasistencia seleccionada
    setModalDevuelve(true); // Abre el modal
  };

  const registraCambios = async(valor) => {
    const parametrosApasar={
      folioInciso:"folio "+valor.folio+" - Inc "+valor.inciso,
      devolvioInasistencia:valor.devolvioInasistencia,
    }
    const tramite=await axios.post("https://wwwa.isepsantafe.edu.ar/expedientes/docentes/creaDevuelve/"+inasistenciaSeleccionada.id,parametrosApasar)
    setParametro(valor);
    
    if(tramite){      alert("Registrada la devolución")  
     
      setModalDevuelve(false)
      props.refresco("dale")
      }
  };

  const anula = async(valor) => {
    const parametrosApasar={
      folioInciso:null,
      devolvioInasistencia:null,
    }
    const tramite=await axios.post("https://wwwa.isepsantafe.edu.ar/expedientes/docentes/creaDevuelve/"+valor.id,parametrosApasar)
    if(tramite){      
      const valorLog={
        idInasistencia:valor.id,
        idUsuario:Usuario.id,
                cambio:valor.devolvioInasistencia,
                folioInciso:valor.folioInciso,
      }
      const creoLogDevuelvo=await axios.post("https://wwwa.isepsantafe.edu.ar/expedientes/docentes/creaLogDevuelve/",valorLog)
      
      if(creoLogDevuelvo){alert("Anulada la devolución")  
      props.refresco("dale")
      }}
  };
  // Función para resetear el formulario con los valores de la inasistencia seleccionada
  const handleReset = () => {
    if (inasistenciaSeleccionada) {
      console.log('Inasistencia seleccionada:', inasistenciaSeleccionada); // Verifica el contenido
      reset({
        devolvioInasistencia: regresaBienFecha(inasistenciaSeleccionada.fechaInasistencia),
        inciso: inasistenciaSeleccionada.inciso || '', // Asegúrate de que este campo esté presente
        folio: inasistenciaSeleccionada.folioInciso || '' // Asegúrate de que folio también esté presente
      });
    }
  };
  

  // Efecto para resetear el formulario cuando se selecciona una inasistencia
  useEffect(() => {
    handleReset(); 
  }, [inasistenciaSeleccionada]);

  return (
    <>
      {modalAbierto ? (
        <ModalInasistencia isOpen={modalAbierto} onClose={() => setModalDevuelve(false)}>
          <div>           
            

            <form className="form_use" onSubmit={handleSubmit(registraCambios)}>
            <h5 style={{ textAlign: "center" }}>Devolución de horas por ausencia del docente {inasistenciaSeleccionada.docente} de la {" "+inasistenciaSeleccionada.division+"° division de la materia "+ inasistenciaSeleccionada.materia}</h5>
            <h4 style={{ textAlign: "center" }}>{inasistenciaSeleccionada.escuela} sede {inasistenciaSeleccionada.sedeCursada + " de fecha " +regresaBienFecha(inasistenciaSeleccionada.fechaInasistencia)}  </h4>
              <div className="form__container">
                <div className="form__group">
                  {errors.devolvioInasistencia?.type === 'required' && (
                    <span className="aviso-campos-loguin">*</span>
                  )}
                  <input
                    type="date"
                    className="form__input"
                    id="devolvioInasistencia"
                    {...register('devolvioInasistencia', { required: true })}
                      min={inasistenciaSeleccionada.fechaInasistencia} // Fecha mínima: hoy
                      max={new Date().toISOString().split('T')[0]} // Fecha máxima: último día de 2024, por ejemplo
                  />
                  <label className="form__label">Fecha de devolución</label>
                  <span className="form__line"></span>
                </div>
                
                <div className="form__group">
                  {errors.inciso?.type === 'required' && (
                    <span className="aviso-campos-loguin">*</span>
                  )}
                  <input
                    type="text"
                    className="form__input"
                    id="inciso"
                    {...register('inciso', { required: true })}
                  />
                  <label className="form__label">Inciso</label>
                  <span className="form__line"></span>
                </div>

                <div className="form__group">
                  {errors.folio?.type === 'required' && (
                    <span className="aviso-campos-loguin">*</span>
                  )}
                  <input
                    type="text"
                    className="form__input"
                    id="folio"
                    {...register('folio', { required: true })}
                  />
                  <label className="form__label">Folio</label>
                  <span className="form__line"></span>
                </div>

                <input type="submit" className="form__submit" value="Continuar" />
              </div>
            </form>
          </div>
        </ModalInasistencia>
      ) : null}
<div style={{overflowX:"auto"}}>
      <div >
        <Table className="table-primary" bordered size="sm">
          <tr style={{ fontSize: "15px" }}>
            <th onClick={() => props.myID("id")}>Ctrol</th>
            <th onClick={() => props.myID("docente")}>Docente</th>
            <th onClick={() => props.myID("motivo")}>Motivo</th>
            <th onClick={() => props.myID("curso")}>Curso</th>
            <th>Escuela</th>
            <th onClick={() => props.myID("materia")}>Materia</th>
            <th onClick={() => props.myID("division")}>Div</th>
            <th onClick={() => props.myID("sede")}>Sede</th>
            <th onClick={() => props.myID("fechaInasistencia")}>Fecha</th>
            <th onClick={() => props.myID("devolvioInasistencia")}>Devolvió</th>
          </tr>
          {DatosDocente &&
            DatosDocente.map((cosas) => (
              <tr key={cosas.id} style={{ fontSize: "12px" }}>
                <th scope="row">{cosas.id}</th>
                <th>{cosas.docente}</th>
                <th>{cosas.motivo}</th>
                <th>{cosas.curso}</th>
                <th>{cosas.escuela}</th>
                <th>{cosas.materia}</th>
                <th>{cosas.division}</th>
                <th>{cosas.sedeCursada}</th>
                <th style={{ textAlign: "center" }}>{regresaBienFecha(cosas.fechaInasistencia)}</th>
                <th style={{ textAlign: "center" }}>
                  {cosas.devolvioInasistencia == null ? (
                    <button className="btn btn-success" onClick={() => creaDevolucion(cosas)}>
                      Devuelve
                    </button>
                  ) : (
                    <h6 onClick={() => anula(cosas)}style={{ borderRadius: "15px", backgroundColor: "#16a085", color: "#f2f4f4" }}>
                      Devolvió el {regresaBienFecha(cosas.devolvioInasistencia)} según {cosas.folioInciso}
                    </h6>
                  )}
                </th>
              </tr>
            ))}
        </Table>
      </div></div>
    </>
  );
}

export default ModuloDevuelveInasistenciasDocentes;
