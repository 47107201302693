import React, { useState, useEffect } from 'react';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario';
import devuelveSedeConsultada from '../tools/devuelveSede'
import devuelveDependenciaConstultada from '../tools/devuelveDependencia'
import axios from 'axios';
import seteaCokieExpediente from './seteaCokieExpediente';
import regresaBienFecha from '../tools/regresaBienFecha'
import '../Expedientes/expedientes.css'
export default function App(props) {
  const exp=props.infoExpediente
var expediente=props.infoExpediente
  const usuario=ObtieneDatosCookies()
  const [muestraResolciones,setMuestraResoluciones]=useState(false)
  const [resolucionesRelacionadas,setResolucionesRelacionadas]=useState([])
  const abreExpedienteProvincia=()=>{
const patronExpediente=/(?:\d{5}|\(\d{3}\))([-\/\.])\d{7}\1\d{1}/
const variable=expediente.idExpMinisterio
const sede=variable.slice(0,5)
const idExpedientemin=variable.slice(6,13)
const digito=variable.slice(14)

  window.open("https://www.santafe.gov.ar/expedientes-web/sie?mesa="+sede+"&numero="+idExpedientemin+"&digito="+digito+"&tipoSIE=1&iniciar=Buscar")
}
const imprimeCaratula=async()=>{
  await seteaCokieExpediente(expediente).then(window.open('/imprime'))
}
const consultaResoluciones=async()=>
  {
    setMuestraResoluciones(false)
    const client2 = axios.create({
      baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/resoluciones/devuelveResolucionesPorExpediente/"
      });
    try {
    
      
    
        const listaFull=await client2.post("/"+expediente.id)
        
      
      
    if(listaFull){
     setResolucionesRelacionadas(listaFull.data)
     setMuestraResoluciones(true)
  }
  }catch{}
  }
useEffect(() => {
  
  consultaResoluciones()
}, [expediente]);

if(exp===false||!exp||exp==""||exp.Expediente==false){
  return "Expediente no encontrado!"
}else{
expediente=exp.Expediente
}




  return (
    <div>
      <div classname="contenedor_Expediente">
        <h4>Expediente:{expediente.dependenciaID}{"-"} {expediente.id}{"-"}{expediente.sedeID}</h4>
      </div>
<td style={{"text-align": "left"}}>
<li style={{"font-weight": "800"}}>Causante: <a style={{"font-weight": "100"}}>{expediente.iniciador  }</a></li>
  <li style={{"font-weight": "800"}}>Concepto: <a style={{"font-weight": "100"}}>{expediente.concepto}</a></li>
  <li style={{"font-weight": "800"}}>Sede de inicio: {devuelveSedeConsultada().filter(categoria=>categoria.id==expediente.sedeID)[0].descripcion}</li>
  <li style={{"font-weight": "800"}}>Fecha de creación: { regresaBienFecha(expediente.createdAt)}</li>
  <li style={{"font-weight": "800"}}>Dependencia de inicio: {devuelveDependenciaConstultada().filter(categoria=>categoria.id==expediente.dependenciaID)[0].descripcion}</li>
  <li style={{"font-weight": "800"}}>Fojas iniciales:<a style={{"font-weight": "100"}}>{" "+expediente.fojasIniciales}</a> </li>
  <li style={{"font-weight": "800"}}>Fecha Anterior:<a style={{"font-weight": "100"}}>{expediente.fechaAnterior?" "+regresaBienFecha(expediente.fechaAnterior):" -"}</a> </li>
  <li style={{"font-weight": "800"}}>Tiene inicio en otro expediente en el ISeP: <a style=
  
  {{"font-weight": "100"}}>{" "}{expediente.idExptAnterior?"SI bajo Nro: "+expediente.idExptAnterior:"NO"}</a></li>
 <li style={{"font-weight": "800"}}>Tiene resoluciones: <a style=
  
  {{"font-weight": "100"}}>{" "}{muestraResolciones?<>{resolucionesRelacionadas.length>0?<>Si: {
    resolucionesRelacionadas.map((resolucion, index) => (

      <>
      
      {resolucion.archivoPdf?<><ul><a
        key={index}
        value={resolucion.id}
        href={`https://wwwa.isepsantafe.edu.ar/resoluciones/${resolucion.archivoPdf}`} target="_blank" rel="noopener noreferrer"
       
      >
        {resolucion.nroResolucion}
      </a></ul></>:<> <ul>{resolucion.nroResolucion + " (no descargable)"} </ul></>}
      
      </>
    )
 
  )}
  </>:"No"}</>:"Cargando..."}</a></li>

  <li style={{"font-weight": "800"}}>Identificador de expediente del Ministerio: <a style={{"font-weight": "100"}}>{" "}{expediente.idExpMinisterio?(expediente.idExpMinisterio+" de fecha:  " + (expediente.fechaMinisterio==null?"No se registra fecha aún":regresaBienFecha(expediente.fechaMinisterio))):"NO POSEE"}</a>
  <div className="input-group-text" class="boton_caratula" style={{"text-align":"center"}}>


  {expediente.idExpMinisterio?(<button  className="btn btn-primary" onClick={abreExpedienteProvincia}>Consultar Expediente Ministerial</button>):""}
  
  {usuario.destino==expediente.dependenciaID?<button  className="btn btn-primary" onClick={()=>props.myID(expediente)}>EDITAR</button>:""}
  
  {usuario.destino==expediente.dependenciaID||usuario.destino==7?(<button className="btn btn-primary "onClick={imprimeCaratula}>Volver a Imprimir Caratula</button>):""}
 {usuario.destino==7&&usuario.nivel==1||usuario.destino==2&&usuario.nivel==1&&usuario.destino!=expediente.dependenciaID?<button  className="btn btn-dark" onClick={()=>props.myID(expediente)}>EDITAR</button>:""}

  </div>
  </li>


  </td>      
  
  <div >

    </div>


 </div>
  );
}


