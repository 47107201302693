import React, { useState, useEffect } from "react";
import './Bienvenido.css'; // Importamos el archivo CSS

const Bienvenido = (props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false); // Inicia el fade-out después de 2 segundos
     
    }, 300);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
    
      props.oculto()
    }, 50);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={`bienvenido-container ${visible ? "" : "fade-out"}`}>
      <h1>Cargando</h1>
    </div>
  );
};

export default Bienvenido;
