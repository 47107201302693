import React, { useState, useEffect} from 'react';
import devuelveSede from '../tools/devuelveSede';
import { Table, Button, Collapse } from 'reactstrap';
import obtieneDatos from '../tools/obtieneDatosCookiesUsuario';
import regresaBienFecha from '../tools/regresaBienFecha';
import regresaBienFechaYDia from '../tools/regresaBienFechayDia';

function ModuloDevuelveDatosDocentes(props) {
  const Usuario = obtieneDatos();
  const [expandedDivisions, setExpandedDivisions] = useState({}); // Cambiamos a un objeto para almacenar el estado de cada división

  let DatosDocente = props.Datos;

  if (Usuario.destino === "1" || Usuario.destino === "7" || Usuario.destino === "10") {
    DatosDocente = props.Datos;
  } else if (Usuario.destino === "11") {
    DatosDocente = props.Datos.filter(
      (x) =>
        x.Escuela === "Educacion a Distancia" &&
        x.sedeCursada === devuelveSede().filter((x) => x.id === Usuario.sedeID)[0].descripcion
    );
  } else if (Usuario.destino === "12" || Usuario.destino === "16") {
    DatosDocente = props.Datos.filter((x) => x.Escuela === "Escuela de Policia");
  } else if (Usuario.destino === "13" || Usuario.destino === "17") {
    DatosDocente = props.Datos.filter((x) => x.Escuela === "Escuela Superior");
  } else if (Usuario.destino === "14" || Usuario.destino === "18") {
    DatosDocente = props.Datos.filter((x) => x.Escuela === "Escuela de Especialidades");
  } else if (Usuario.destino === "15" || Usuario.destino === "19") {
    DatosDocente = props.Datos.filter((x) => x.Escuela === "Escuela de Investigaciones");
  }
  useEffect(() => {
    // Inicializar expandedDivisions con todas las divisiones abiertas (true)
    const initialExpandedDivisions = {};
    props.Datos.forEach((cosas) => {
      initialExpandedDivisions[cosas.Division] = true;
    });
    setExpandedDivisions(initialExpandedDivisions);
  }, [props.Datos]);

  const toggleDivision = (divisionId) => {
    setExpandedDivisions((prevState) => ({
      ...prevState,
      [divisionId]: !prevState[divisionId],
    }));
  };

  const usuario = (
    <>
    <div>

        {props.Inasistencias.length > 0 ? (
          <></>
        ) : (
          <h4>No registra Inasistencias de las materias actuales</h4>
        )}
      </div>
      <h5>Materias Actuales en su escuela:</h5>
      <Table className="table-primary" bordered size="sm">
        <thead>
          <tr style={{ fontSize: "15px" }}>
            <th style={{ width: "50px" }}>#</th>
            <th style={{ width: "80px" }}>Division</th>
            <th style={{ width: "200px" }}>Materia</th>
            <th>Escuela</th>
            <th>Horas</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {DatosDocente &&
            DatosDocente.map((cosas, index) => (
              <React.Fragment key={cosas.id}>
                <tr className="aa" style={{ fontSize: "12px" }}>
                  <th scope="row">{index + 1}</th>
                  <td>{cosas.Division}</td>
                  <td style={{ color: cosas.esRentada === "SI" ? "black" : "red" }}>
                    {cosas.Materia} {cosas.esRentada !== "SI" && "(S. Ordinario)"}
                  </td>
                  <td>{cosas.Escuela}</td>
                  <td style={{ textAlign: "center" }}>{cosas.Horas}</td>
                  <td>
                    <Button id="impr" color="success" size="sm" onClick={() => props.myID(cosas)}>
                      Crear Inasistencia
                    </Button>
                    {props.Inasistencias.length>0&&props.Inasistencias.find(x=>x.division==cosas.Division)?<Button id="impr"
                      color="info"
                      size="sm"
                      onClick={() => toggleDivision(cosas.Division)}
                    >
                      {expandedDivisions[cosas.Division] ? "Ocultar" : "Ver Inasistencias"}
                    </Button>:""}
                  </td>
                </tr>
                {props.Inasistencias.length>0&&props.Inasistencias.find(x=>x.division==cosas.Division)? <tr>
                  <td colSpan="6">
                    <Collapse isOpen={expandedDivisions[cosas.Division]}>
                      <div style={{ padding: "10px" }}>
                        <Table bordered size="sm">
                          <thead>
                            <tr style={{ fontSize: "15px" }}>
                              <th style={{ width: "50px" }}>#</th>
                              <th style={{ width: "200px" }}>Fecha Inasistencia</th>
                              <th style={{ width: "200px" }}>Motivo</th>
                              <th style={{ width: "200px" }}>Se cargo</th>
                              <th style={{ width: "200px" }}>Devolvio</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.Inasistencias.filter(
                              (inasistencia) => inasistencia.division == cosas.Division
                            ).map((inasistenciac, inasistenciaIndex) => (
                              <tr key={inasistenciac.id} style={{ fontSize: "12px" }}>
                                <th scope="row">{inasistenciaIndex + 1}</th>
                                <td>{regresaBienFecha(inasistenciac.fechaInasistencia)}</td>
                                <td>{inasistenciac.motivo}</td>
                                <td>{regresaBienFechaYDia(inasistenciac.createdAt)}</td>
                                <td>{inasistenciac.devolvioInasistencia ? regresaBienFecha(inasistenciac.devolvioInasistencia) : "-"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Collapse>
                  </td>
                </tr>:""}
              </React.Fragment>
            ))}
        </tbody>
      </Table>
    </>
  );

  return <div>{usuario}</div>;
}

export default ModuloDevuelveDatosDocentes;
