import Cookies from 'universal-cookie';
import { Table } from "reactstrap";
import './ModuloDevuelveCadetes.css'; // Import a custom CSS file

function ModuloDevuelveCadetes(props) {
  const cookies = new Cookies();
  var cantidad2 = 1;

  const usuarioPrivilegios = (
    <>
      <Table className="table-primary" bordered size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>DNI</th>
            <th>Apellido</th>
            <th>Nombres</th>
            <th>Sede</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {!props.cosas ? "" : props.cosas.map((cosas, index) => (
            <tr key={cosas.id} onClick={() => props.myID(cosas)}>
              <th scope="row">{cantidad2++}</th>
              <th>{cosas.cadeteDni}</th>
              <th>{cosas.cadeteApellido}</th>
              <th>{cosas.cadeteNombre}</th>
              <th>{cosas.cadeteSede}</th>
              <th>{cosas.cadeteEstado}</th>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );

  return (
    <div>
      {usuarioPrivilegios}
    </div>
  );
}

export default ModuloDevuelveCadetes;

