import ObtieneDatosCookies from "../tools/obtieneDatosCookiesUsuario";

const convierteExpediente=(data)=>{
  console.log(data)

  const usuario=ObtieneDatosCookies()
    function primeraMayuscula(str) {
     
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    return {
            idExptAnterior:data.idExptAnterior ? data.idExptAnterior : null,
            idExptPosterior:data.idExptAnterior ? data.idExptPosterior : null,
            fechaAnterior:data.fechaAnterior?data.fechaAnterior:null,
            fojasIniciales:data.fojasIniciales,
            iniciador:primeraMayuscula(data.iniciador),
            idCategoria:data.idCategoria,
            concepto:data.concepto,
            esDeCadete:data.esDeCadete,
            idExpMinisterio:data.idExpMinisterio ? data.idExpMinisterio:null,
            fechaMinisterio:data.fechaMinisterio ? data.fechaMinisterio:null,
            
    }
}
export default convierteExpediente;