import React, { useState } from 'react';

const FileUpload = (props) => {
  const [file, setFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [envia, setEnvia] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Verificar que sea un archivo .docx
      const fileExtension = selectedFile.name.split('.').pop();
      if(props.tipo=='docx'||props.tipo=='pdf'){
        if (fileExtension !== props.tipo && selectedFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'||fileExtension !== props.tipo && selectedFile.type !== 'application/pdf') {
          setErrorMessage('Solo se permiten archivos .'+props.tipo);
          setFile(null);
          return;
        }
      }else{      if (fileExtension !== props.tipo && selectedFile.type !== 'application/pdf') {
        setErrorMessage('Solo se permiten archivos .'+props.tipo);
        setFile(null);
        return;
      }}


      setErrorMessage(''); // Limpiar el mensaje de error si es válido

      // Crear un nuevo objeto File con un nuevo nombre
      const newFileName = `Resolucion ${props.resolucion}.${props.tipo}`; // Cambia esto al nombre que desees
      const newFile = new File([selectedFile], newFileName, { type: selectedFile.type });

      setFile(newFile);

      // Estimamos el tiempo de carga
      const fileSize = newFile.size; // en bytes
      const uploadSpeed = 500000; // Velocidad promedio de carga en bytes por segundo (500 kb/s)
      const estimatedSeconds = Math.ceil(fileSize / uploadSpeed);
      setEstimatedTime(estimatedSeconds);
    }
  };

  const handleUpload = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setIsUploading(true);
    setUploadPercentage(0);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://wwwa.isepsantafe.edu.ar/expedientes/uploads', true);

    // Manejo del progreso de carga
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded * 100) / event.total);
        setUploadPercentage(percentComplete);

        // Actualizar el tiempo estimado restante
        const timeElapsed = Math.ceil((event.loaded / 500000)); // Tiempo transcurrido en segundos
        const timeRemaining = estimatedTime - timeElapsed;
        setEstimatedTime(timeRemaining > 0 ? timeRemaining : 0);
      }
    };

    xhr.onload = () => {
      setEnvia(false);
      if (xhr.status === 200) {
        alert('Archivo subido correctamente');
        setFile(null);
        setUploadPercentage(0);
        setEstimatedTime(null);
        setEnvia(true);
        props.myID(`Resolucion ${props.resolucion}.${props.tipo}`);
      } else {
        alert('Error al subir el archivo');
      }
      setIsUploading(false);
    };

    xhr.onerror = () => {
      alert('Error de red');
      setIsUploading(false);
    };

    xhr.send(formData);
  };

  return (
    <div>
      <h1>Subir Archivo</h1>
      <input type="file" onChange={handleFileChange} />
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {file && (
        <div>
          <p>Archivo: {file.name}</p>
          <p>Tamaño: {(file.size / (1024 * 1024)).toFixed(2)} MB</p>
          {estimatedTime !== null && (
            <p>Tiempo estimado de carga: {estimatedTime} segundos</p>
          )}
          <button onClick={handleUpload} disabled={isUploading}>
            {isUploading ? 'Subiendo...' : 'Subir'}
          </button>
          {isUploading && (
            <div style={{ marginTop: '10px' }}>
              <div style={{ width: '100%', backgroundColor: '#f3f3f3' }}>
                <div
                  style={{
                    width: `${uploadPercentage}%`,
                    height: '20px',
                    backgroundColor: '#4caf50',
                    transition: 'width 0.5s',
                  }}
                />
              </div>
              <p>{uploadPercentage}%</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
