import { useForm } from 'react-hook-form';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import convierteCadete from  './convierteCadete.js'
import convierteSoloEstado from './convierteSoloEstado.js'
import convierteCadeteReincorporar from  './convierteCadeteReincorporar.js'
import { useState } from 'react';
import swal from 'sweetalert'
import Modal from 'react-modal';
import ModalLtb from './formEditaLtb'
import ModalBaja from './formEditaBaja'
import ModalLicEsp from './formEditaLicEsp.js'
import ModalCambiaSede from './formEditaCambiaSede.js';
import ModalREincorporaYa from './formReincorporaYa.js';
import regresaBienFecha from '../tools/regresaBienFecha.js'


function FormEditaCadete (props) {

  const cookies=new Cookies();
  const navitate = useNavigate();
  const ID=cookies.get('id');
  const [modalLtbIsOpen, setLtbIsOpen] = useState(false);
  const [modalBajaIsOpen, setBajaIsOpen] = useState(false);
  const [modalLicEspIsOpen, setLicEspIsOpen] = useState(false);
  const [modalCambioSedeIsOpen, setModalCambioSede] = useState(false);
  const [modalReincorporaYaIsOpen, setReincorporaYaIsOpen] = useState(false);
  const [datosVarios, setDatosVarios] = useState([]);
  const [mostrarDatos,setMostrarDatos]=useState(false)
  const [cargo,setCargo]=useState(false)
  const customStyles = {
    content: {
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
 
    },
  };

  function openModalLtb() {
    setLtbIsOpen(true);
  }
  

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    
  }
  function closeModalLtb() {
    setLtbIsOpen(false);
   
    props.esperoCerrar("false");
 
  }
  function closeModalCambioSede() {
    setModalCambioSede(false);

    props.esperoCerrar("false");
    
   
    
 
  }
  function openModalBaja() {
    setBajaIsOpen(true);
  }
  function openModalReincorporaYa() {
    setReincorporaYaIsOpen(true);
  }
function openModalCambioSede(){
  setModalCambioSede(true)

}
  function closeModalBaja() {
    setBajaIsOpen(false)
    props.esperoCerrar("false");
  }
  function openModalLicEsp() {
    setLicEspIsOpen(true);
  }

  function closeModalLicEsp() {
    setLicEspIsOpen(false)
    props.esperoCerrar("false");
  }
  
  var cierro=false;
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.edu.ar/expedientes/'
  
const parametro=props.datosCadete
const usuario=ObtieneDatosCookies()


  const store=async (data)=>{
data.idUsuario=usuario.id
   const respuesta=await axios.put(URI+"cadetes/actualiza/"+data.cadeteDni,convierteCadete(data))
   
   if(respuesta){
    queHago(respuesta.data).then(cierro?()=>props.esperoCerrar("dale"):"")
   }
   
}
const regularizoCadete =async()=> {

  
  try {
    const respuesta=await axios.put(URI+"cadetes/actualiza/"+parametro.cadeteDni,convierteSoloEstado(parametro,"REGULAR"))
    queHago(respuesta.data).then(cierro?()=>props.esperoCerrar("dale"):"")
  } catch (error) {
  console.error(error)  }
      }
  





const cambioEstado =async()=> {

  
    if(parametro.cadeteEstado=="Propedeutico"){
    
    
      const respuesta=await axios.put(URI+"cadetes/actualiza/"+parametro.cadeteDni,convierteSoloEstado(parametro,"Ausente"))
      queHago(respuesta.data).then(cierro?()=>props.esperoCerrar("dale"):"")

    }
    else{
      const respuesta=await axios.put(URI+"cadetes/actualiza/"+parametro.cadeteDni,convierteSoloEstado(parametro,"Propedeutico"))

      queHago(respuesta.data).then(cierro?()=>props.esperoCerrar("dale"):"")
    }
  
}
  const queHago=async(respuesta)=>{

    if(respuesta.message==="Datos actualizados correctamente!!"){
      swal("Exito en la actualizacion del estado del DNI :"+respuesta.respuesta)
      cierro=true
    }
    else{
      swal("Algo Fallo!, consulte con el area Tecnica error A003!!!")
     
    
       }}
   
  
  const {register, formState: { errors }, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  



  const onSubmit = (data) => {

    store(data)
  }
const muestrame=()=>
{const date=new Date(props.estadoCadete.ltbs[0].createdAt)
swal("Ltb Motivo: "+props.estadoCadete.ltbs[0].motivo+" Descripcion: "+props.estadoCadete.ltbs[0].descripcion+ ". Fecha Creado: "+date.toLocaleDateString())
}
const muestrameReincorporacion=()=>
{const date=new Date(props.estadoCadete.reincorporaciones[0].createdAt)
swal("Reincorporacion generadalicEsp por "+props.estadoCadete.reincorporaciones[0].motivo+ " en fecha "+date.toLocaleDateString())
}
const muestrameLicEsp=()=>
{const date=new Date(props.estadoCadete.licEsp[0].createdAt)
swal("Licencia Especial generada por "+props.estadoCadete.licEsp[0].motivo+ " en fecha "+date.toLocaleDateString() +" segun se describio por: "+props.estadoCadete.licEsp[0].descripcion+" en la promocion "+props.estadoCadete.licEsp[0].promocion+ " Resolucion: "+props.estadoCadete.licEsp[0].resolucionLicEsp + " en fecha: "+ regresaBienFecha(props.estadoCadete.licEsp[0].fechaLicEsp))
}
const muestrameBaja=()=>
{const date=new Date(props.estadoCadete.bajas[0].createdAt)
const fechaBaja=new Date()
swal("Baja generada por "+props.estadoCadete.bajas[0].motivo+ " en fecha "+date.toLocaleDateString()+" segun se describio por: "+props.estadoCadete.bajas[0].descripcion+" en la promocion "+props.estadoCadete.bajas[0].promocion+ " Resolucion: "+props.estadoCadete.bajas[0].resolucionBaja + " en fecha: "+ regresaBienFecha(props.estadoCadete.bajas[0].fechaBaja)
)
}
const handleSelectChange = (event) => {
  const selectedId = event.target.value;
  window.open(`/consultaExpediente?nroExpediente=${selectedId}`, '_blank')
  console.log(`Opción seleccionada: ${selectedId}`);
};
  return (
    <>
    <div >
      <div className='input-group-text mb-3'>

       <strong>Baja registrada:</strong> {props.estadoCadete.bajas&&props.estadoCadete.bajas[0]?<a onClick={muestrameBaja}><h4 style={{color:"blue", paddingRight:"2px"}}>Si </h4></a>:<h6>-</h6>}
       <strong>Ltb registrada:</strong>{props.estadoCadete.ltbs&&props.estadoCadete.ltbs[0]?<a onClick={muestrame}><h4 style={{color:"blue", paddingRight:"2px"}}>Si </h4></a>:<h6>-</h6>}
       <strong>Reincorporacion registrada:</strong>{props.estadoCadete.reincorporaciones&&props.estadoCadete.reincorporaciones[0]?<a onClick={muestrameReincorporacion}><h4 style={{color:"blue", paddingRight:"2px"}}>Si </h4></a>:<h6>-</h6>}
       <strong>Licencia Especial registrada:</strong>{props.estadoCadete.licEsp&&props.estadoCadete.licEsp[0]?<a onClick={muestrameLicEsp}><h4 style={{color:"blue", paddingRight:"2px"}}>Si </h4></a>:<h6>-</h6>}{console.log(props.estadoCadete)}
       <strong>Posee Expedientes Relacionados:</strong>
{
  props.estadoCadete.exptesRelacionados && props.estadoCadete.exptesRelacionados.length > 0 ? 
   
  props.estadoCadete.exptesRelacionados.map((expediente, index) => (
      <a
        key={index}
        value={expediente.id}
        href={`/consultaExpediente?nroExpediente=${expediente.id}`} target="_blank" rel="noopener noreferrer"
      >
        {expediente.id}
      </a>
    )
 
  ) : (
    "-"
  )
}

      </div>
    <Modal
        isOpen={modalLtbIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalLtb}
        style={customStyles}
   
      >
       <div className=" card-header" >  <a style={{alignSelf:"center"}}><strong>Proceso de Licenciamiento de Tramite de Baja:</strong></a>
      </div>
       
      <ModalLtb datosCadete={parametro} myID={(value2)=>closeModalLtb()}/>
         
      </Modal>
      <Modal
        isOpen={modalReincorporaYaIsOpen}
      
        onRequestClose={()=>setReincorporaYaIsOpen(false)}
        style={customStyles}
   
      >
       <div className=" card-header" >  <a style={{alignSelf:"center"}}><strong>Proceso de Reincorporacion en esta Promocion:</strong></a>
      </div>
       
      <ModalREincorporaYa datosCadete={parametro} myID={()=>setReincorporaYaIsOpen(false)}/>
         
      </Modal>
      <Modal
        isOpen={modalCambioSedeIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalCambioSede}
        style={customStyles}
   
      >
       <div className=" card-header" >  <a style={{alignSelf:"center"}}><strong>Proceso de Cambio de Sede:</strong></a>
      </div>
       
      <ModalCambiaSede datosCadete={parametro} myID={(value2)=>closeModalCambioSede(value2)}/>
         
      </Modal> 
      <Modal
        isOpen={modalBajaIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalBaja}
        style={customStyles}
   
      >
       <div className=" card-header" >  <a style={{alignSelf:"center"}}><strong>Proceso de Baja:</strong></a>
      </div>
       
      <ModalBaja datosCadete={parametro} estadoCadete={props.estadoCadete} myID={(value2)=>closeModalBaja()}/>
         
      </Modal>
      <Modal
        isOpen={modalLicEspIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalLicEsp}
        style={customStyles}
   
      >
       <div className=" card-header" >  <a style={{alignSelf:"center"}}><strong>Proceso de Licencia Especial:</strong></a>
      </div>
       
      <ModalLicEsp datosCadete={parametro} myID={(value2)=>closeModalLicEsp()}/>
         
      </Modal>
      <form  onSubmit={handleSubmit(onSubmit)}>
       

<div class="row">
<div class='input-group-text' >

          <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" placeholder="Apellido" 
         {...register('cadeteApellido', {
        required: true,
        minLength: 2,
          })}/>
        <label for="floatingInput">Apellido</label>
    </div>
      <div class="form-floating mb-3" >
      <input type="text" class="form-control" id="floatingNombres" placeholder="Nombres" 
      {...register('cadeteNombre', {
        required: true,
        minLength: 3,
      })}/>
      <label for="floatingNombres">Nombres</label>
    </div>
    <div class="form-floating mb-3">
      <input type="number" class="form-control" id="floatingDNI" placeholder="DNI" 
      {...register('cadeteDni', {
        required: true,
        minLength: 8
      })}/>
      <label for="floatingDNI">DNI</label>
    </div>
    <div class="form-floating mb-3">
  <select class="form-select" id="floatingCadeteGenero" aria-label="CadeteGenero"
  {...register('cadeteGenero', {
    required: true
  })}
  >
   
    <option class="mb-3" value="Masculino">Masculino</option>
    <option class="mb-3" value="Femenino">Femenino</option>
    <option class="mb-3" value="X">X</option>
    
    
  </select>
  <label for="form-floating mb-3">Genero</label>
</div>
</div>

<div class="row">

          <div class='input-group-text' >
          <div class="form-floating mb-3">
      <input type="number" class="form-control" id="floatingCelular" placeholder="Celular" 
      {...register('cadeteCelular', {

      })}/>
      <label for="floatingCelular">Celular</label>
    </div>
    <div class="form-floating mb-3">
      <input type="text" class="form-control" id="floatingDomicilio" placeholder="Domiclio" 
      {...register('cadeteDomicilio', {
       
      })}/>
      <label for="floatingDomicilio">Domicilio</label>
    </div>
    <div class="form-floating mb-3">
      <input type="text" class="form-control" id="floatingcadeteCiudad" placeholder="Domiclio" 
      {...register('cadeteCiudad', {
       
      })}/>
      <label for="floatingcadeteCiudad">Ciudad</label>
    </div>
    <div class="form-floating mb-3">
      <input type="text" class="form-control" id="floatingcadeteUR" placeholder="U.Regional" 
      {...register('cadeteUR', {
       
      })}/>
      <label for="floatingcadeteUR">U.Regional</label>
    </div>


<div>
  
</div>
</div>

<div class='input-group-text' >
    <div class="form-floating mb-3">
  <input type="text"class="form-control" disabled id="floatingCadeteSede" aria-label="CadeteSede"
  {...register('cadeteSede', {
    required: true
  })}
  />
  <label for="form-floating mb-3">Sede</label>
</div>
<div class="form-floating mb-3">
  <input value={parametro.promocion} class="form-control" placeholder="CURSO" id="floatingCurso" 
   {...register('promocion', {
    

  })}/>
  <label for="floatingCurso">Promoción</label>
</div>
<div class="form-floating mb-3" >
      <input value={parametro.cadeteEstado}type="text" class="form-control" id="floatingNombres" placeholder="Nombres" 
      {...register('cadeteEstado', {
        required: true,
        minLength: 3,
      })}/>
      <label for="floatingNombres">Estado Academico</label>
    </div>
</div>


</div>
<div class='input-group-text' style={{alignContent:"center",alignSelf:"center",gridRowStart:"auto"}}>
  <div style={{paddingBlock:"5px"}}>

{usuario.nivel<3?<input type="submit" value="Actualizar Datos"  className="btn btn-success" />:""}
    
</div>
{parametro.cadeteEstado!="BAJA"?<>
{
  parametro.cadeteEstado!="LTB"&&usuario.destino=="2"&&usuario.nivel<4|| parametro.cadeteEstado!="LTB"&&usuario.destino=="7"&&usuario.nivel<4|| parametro.cadeteEstado!="LTB"&&usuario.destino=="9"&&usuario.nivel<4|| parametro.cadeteEstado!="LTB"&&usuario.destino=="16"&&usuario.nivel<4?<input type="button" value="LTB" onClick={openModalLtb} className="btn btn-warning" style={{paddingBlock:"5px"}}/>:""
}
{
  parametro.cadeteEstado!="LiEs"&& usuario.destino=="2"||parametro.cadeteEstado!="LiEs"&&usuario.destino=="7"?<input type="button" value="Licencia Especial" onClick={openModalLicEsp} className="btn btn-primary" />:""
}

{usuario.destino=="2"||usuario.destino=="7"?
  <input type="button" value="CAMBIO DE SEDE" onClick={openModalCambioSede} className="btn btn-dark" />:""

}


{usuario.destino=="2"||usuario.destino=="7"?
  <input type="button" value="BAJA" onClick={openModalBaja} className="btn btn-danger" />:""

}

{usuario.destino=="9"||usuario.destino=="7"||usuario.destino=="2"?
  parametro.cadeteEstado!="Propedeutico"&&parametro.cadeteEstado!="REGULAR"?<input type="button" value="Propedeutico" onClick={cambioEstado} className="btn btn-primary" />:<input type="button" value="Ausente!" onClick={cambioEstado} className="btn btn-warning" />:""
}
{usuario.destino=="7"||usuario.destino=="2"?
  parametro.cadeteEstado!="REGULAR"?<input type="button" value="REGULAR" onClick={regularizoCadete} className="btn btn-outline-secondary" />:"":""
}
</>:<>{usuario.destino=="2"||usuario.destino=="7"?
  <input type="button" value="REINCORPORA en ESTA PROMOCION" onClick={openModalReincorporaYa} className="btn btn-danger" />:""

}</>}





</div>

</div>
 </form>

   
        
        </div>
 
        </>
  )}

export default FormEditaCadete;
